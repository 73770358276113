/**
 * Converts UV measurement from µW/cm² to µW/lm
 * @param microWperCM2 UV measurement in µW/cm²
 * @param illuminance Illuminance in lux
 * @returns UV measurement in µW/lm
 */
export default function calcMicroWperCm2toMicroWperLumen(
  microWperCM2: number,
  illuminance: number
): number {
  if (illuminance <= 0) {
    return 0;
  }
  return (microWperCM2 * 100) / illuminance;
}