const SegmentUtil = {
	upsell: {
		// realtimealerts: 'Real Time Alerts Upsell',
		// profeature: 'Pro Feature Upsell'
	},
	actions: {
		subscribepro: 'Subscribe to Pro',
		abandonsubscribepro: 'Abandon Checkout',
		cancelprosubscription: 'Cancel Pro Subscription',
		signedout: 'Signed Out',
		searchstart: 'Search Start',
		accountcreate: 'Account Created',
		inviteaccepted: 'Invite Accepted',
		signedup: 'Signed Up',
		multiaccountinviteaccepted: 'Multiaccount Invite Accepted',
		viewsamplescore: 'View Sample Score',
		setalert: 'Set an Alert',
		editalert: 'Edit an Alert',
		deletealert: 'Delete an Alert',
		changerole: 'User Role Changed',
		addobservation: 'Add Observation',
		performancereport: 'View Performance Report',
		facilityreport: 'View Facility Report',
		importdata: 'Import Data',
		exportdata: 'Export Data',
		importsampledata: 'Import Sample Data',
		sendinvite: 'Sent Invite',
		removeuser: 'Account Removed User',
		viewhomepage: 'View Homepage',
		changegraph: 'Change Graph Parameters',
		changegraphtype: 'Change Graph Type',
		changegraphsensor: 'Graph Sensors Changed',
		changegraphrange: 'Graph Date Range Changed',
		changegraphaggregation: 'Graph Aggregation Changed',
		importcomplete: 'Import Completed',
		exportcomplete: 'Export Completed',
		profilecomplete: 'Profile Completed',
		emailverified: 'Email Verified',
		addlocation: 'Add Location',
		addspace: 'Add Space',
		addpestmonitor: 'Add Pest Monitor',
		viewpestmonitorhistory: 'View Pest Monitor history',
		replacepestmonitor: 'Replace Pest monitor',
		ipmchanegtab: 'IPM change tab',
		addipmobservation: 'Add IPM observation',
		addpest: 'Add pest to an observation',
		addcomment: 'Add comment',
		pestidentifybuttonaction: 'Pest Identify button clicked',
		changeanalyzeparams: 'Analyze date range change',
		addimage: 'Add Image',
		addpestentry: 'Add a new pest list entry',
		modifypestlist: 'Modify pest list',
		clickreferralbutton: 'Clicked referral button',
		clickcommunitybutton: 'Clicked community button',
		sendreferralemail: 'Send referral email',
		resendinvitation: 'Resent Invite',
		graphreadingschange: 'Graph Readings Changed',
		graphzoom: 'Zoomed in / out of graph',
		sort: 'Sort Event',
		filter: 'Filter Event',
		trackeventpage: 'Events Tab',
		editeventprofile: 'Open Event Edit Drawer',
		createeventprofile: 'Create Event Profile',
		activateevent: 'Activate Event Profile',
		deactivateevent: 'Deactivate Event Profile',
		deleteevent: 'Delete Event Profile',
		addsubscriber: 'Add Subscriber to Event',
		removesubscriber: 'Remove Subscriber from Event',
		saveeventprofile: 'Save Event Profile',
		openeventdetaillegacy: 'Open Event Details with a legacy alert event',
		addCommentToEventDetail: 'Add comment to event detail',
		updateCause: 'Update cause of event detail',
		updateResolution: 'Update resolution of event detail',
		filterSensorsBySearchBar: 'Filter Sensors using search bar',
		filterSensorsByStatusDropdown: 'Filter Sensors using status dropdown',
		filterSensorsByLocationDropdown: 'Filter Sensors using location dropdown',
		filterPestMonitorsBSearchBar: 'Filter Pest Monitors using search bar',
		ipmAnalyzeFilterByLocationSpace: 'IPM Analyze filter by location/space',
		ipmAnalyzeFilterByPest: 'IPM Analyze filter by pest/lifecycle',
		start_buy_starter_kit: 'Start the Buy Starter Kit process',
		ad_starter_kit_shown: 'Starter kit offer shown',
		ad_starter_kit_click: 'Starter kit offer clicked',
		subscription_page_viewed: 'Subscription page viewed',
		show_update_subscription: 'Show the Update Subscription process',
		start_update_subscription: 'Start the Update Subscription process',
		confirm_update_subscription: 'Confirm the Update Subscription process',
		success_update_subscription: 'Success the Update Subscription process',
		clickStore: 'Clicked on Store',
		analyticsv2FilterByLocation: 'Analytics v2 filter by location',
		analyticsv2FilterBySpace: 'Analytics v2 filter by space',
		analyticsv2FilterBySensor: 'Analytics v2 filter by sensor',
		analyticsv2Query: 'Analytics v2 query',
		analyticsv2ShowObservations: 'Analytics v2 show observation',
		analyticsv2HideObservations: 'Analytics v2 hide observation',
		analyticsv2ShowDots: 'Analytics v2 show dots',
		analyticsv2HideDots: 'Analytics v2 hide dots',
		analyticsGoNewAnalytics: 'Go new analytics',
		analyticsGoOldAnalytics: 'Go old analytics',
		analyticsv2ShowKPIs: 'Analytics v2 show KPIs',
		analyticsv2HideKPIs: 'Analytics v2 hide KPIs',
		scalechange: 'Scale changed',
		analyticsv2TimeRangeChange: 'Analytics V2 Time Range Change',
	},
	track: (action, newPayload) => {
		const payload = newPayload || {};
		payload.category = 'app';
		if (window && window.analytics) {
			window.analytics.track(action, payload);
		}
	},
	identifyAndGroup: user => {
		const subsdata = [];
		const subs = user.subscription;
		if (subs && subs.length > 0) {
			subs.forEach(sub => {
				if (sub.type !== 'free') {
					subsdata.push(sub.type);
				}
			});
		}
		if (window && window.analytics) {
			const uniqueSubData = [...new Set(subsdata)];
			let newSubData;
			if (uniqueSubData.length > 1) {
				newSubData = uniqueSubData;
			} else {
				newSubData = uniqueSubData[0];
			}
			window.analytics.identify(
				user.uuid,
				{
					name: `${user.first_name} ${user.last_name}`,
					email: user.email,
					role: user.role,
					createdAt: user.created_at,
					monitor: user.has_active_sensor,
					subscription: newSubData
				},
				{},
				() => {
					window.analytics.group(
						user.customer.uuid,
						{
							name: user.customer.name,
							createdAt: user.customer.created_at
						},
						{},
						() => {
							window.analytics.track('Signed In', { category: 'app' });
						}
					);
				}
			);
		}
	}
};

SegmentUtil.identify = (user, subsdata) => {
	const data = [];
	let hasmonitor = false;
	if (subsdata && subsdata.length > 0) {
		subsdata.forEach(sub => {
			if (sub.type !== 'free') {
				data.push(sub.type);
			}
			hasmonitor = sub.has_active_sensor;
		});
	}

	const uniqueSub = [...new Set(data)];
	let newData;
	if (uniqueSub.length > 1) {
		newData = uniqueSub;
	} else {
		// eslint-disable-next-line prefer-destructuring
		newData = uniqueSub[0];
	}
	if (window && window.analytics) {
		window.analytics.identify(user.uuid, {
			name: `${user.first_name} ${user.last_name}`,
			email: user.email,
			role: user.role,
			createdAt: user.created_at,
			monitor: hasmonitor,
			subscription: newData
		});
	}
};

export default SegmentUtil;
