import React, { useEffect, useState } from 'react';

import { useStoreon } from 'storeon/react';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import { Box, Typography, Button, Tooltip, Snackbar } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import SegmentUtil from '../../../util/SegmentUtil';
import EventProfileDrawer from '../EventProfileDrawer';
import Loading from '../../common/elements/Loading';
import EventSubscribe from '../Modals/EventSubscribe';
import EventSettingsList from './EventSettingsList';
import useStyles from './styles';
import theme from '../../../style/themev2';
import EmptyHistory from '../EmptyHistory';
import EventStepper from '../EventStepper';
import useRoles from 'legacy/hooks/useRoles';
import ConfirmationModal from '../../common/drawer/v2/ConfirmationModal';
import Api from '../../../api/Api';

const schema = yup.object({
	subscribers: yup.array().of(yup.string().email())
});

const DeleteButton = withStyles(theme => ({
	root: {
		color: red[500],
		borderColor: red[500],
		'&:hover': {
			backgroundColor: 'rgba(211, 47, 47, 0.04)',
			borderColor: red[700],
			color: red[700]
		},
		'&:disabled': {
			color: theme.palette.action.disabled,
			borderColor: theme.palette.action.disabled
		}
	}
}))(Button);

function EventSettings({ setFab }) {
	const { search } = useLocation();

	const classes = useStyles();

	const { dispatch, eventProfiles, users } = useStoreon(
		'eventProfiles',
		'users'
	);

	const { isUser, isAdmin } = useRoles();

	const methods = useForm({
		defaultValues: {
			subscribers: []
		},
		shouldFocusError: true,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});
	const [open, setOpen] = useState(false);
	const [openStepper, setOpenStepper] = useState(false);
	const [currentEventProfile, setCurrentEventProfile] = useState();
	const [listEventProfiles, setListEventProfiles] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedProfiles, setSelectedProfiles] = useState([]);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (listEventProfiles?.length > 0) {
			const query = new URLSearchParams(search);
			const eventProfileUuid = query.get('eventprofile');

			const foundEventProfile = listEventProfiles.find(
				({ uuid }) => uuid === eventProfileUuid
			);

			if (foundEventProfile) {
				setCurrentEventProfile(foundEventProfile);

				dispatch('navstate/update', {
					drawerOpen: true,
					drawerContent: <EventProfileDrawer eventProfile={foundEventProfile} />
				});
			}
		}
	}, [listEventProfiles, search]);

	const handleClickRow = eventProfile => {
		const action = eventProfile.uuid
			? SegmentUtil.actions.editeventprofile
			: SegmentUtil.actions.createeventprofile;

		SegmentUtil.track(action);

		dispatch('navstate/update', {
			drawerOpen: true,
			drawerContent: <EventProfileDrawer eventProfile={eventProfile} />
		});
	};

	const handleSubscribeClick = eventProfile => {
		setCurrentEventProfile(eventProfile);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setCurrentEventProfile(null);
	};

	const handleSaveSubs = () => {
		const getUserInfo = email => {
			const foundUser = users.find(currentUser => currentUser.email === email);

			if (foundUser) return foundUser.uuid;

			return null;
		};

		const buildSubscribersList = subscribers => {
			if (!subscribers) return [];

			return subscribers.map(email => ({
				email,
				subscriberUuid: getUserInfo(email)
			}));
		};

		const subscribersList = buildSubscribersList(
			methods.getValues('subscribers')
		);

		subscribersList.map(() =>
			SegmentUtil.track(SegmentUtil.actions.addsubscriber, {
				category: 'app',
				where: 'Standalone Modal'
			})
		);

		const newEditedEventProfile = {
			uuid: currentEventProfile.uuid,
			subscribersList
		};

		dispatch('eventprofiles/edit', newEditedEventProfile);
		dispatch('eventprofiles/save');
		dispatch('eventprofiles/refresh');

		handleClose();
	};

	const handleClickNewEvent = () => {
		setOpenStepper(true);
	};

	const handleCancel = () => setOpenStepper(false);

	useEffect(() => {
		if (eventProfiles) {
			setListEventProfiles(eventProfiles.list);
			setIsLoading(false);
		}
	}, [eventProfiles]);

	useEffect(() => {
		setFab(null);
		dispatch('navstate/update', { pageTitle: 'Setup' });
		dispatch('eventprofiles/refresh');
		dispatch('locations/refresh');
		dispatch('spaces/refresh');
		dispatch('sensors/refresh');
	}, []);

	const handleSelectProfile = (uuid, isSelected) => {
		setSelectedProfiles(prev =>
			isSelected ? [...prev, uuid] : prev.filter(id => id !== uuid)
		);
	};

	const handleDeleteClick = () => {
		setShowDeleteDialog(true);
	};

	const handleConfirmDelete = async () => {
		try {
			await Api.deleteEventProfiles(selectedProfiles);
			setSelectedProfiles([]);
			setShowDeleteDialog(false);
			dispatch('eventprofiles/refresh');
		} catch (error) {
			setError(
				error.response?.data?.message ||
					'Failed to delete event profiles. Please try again.'
			);
			setShowDeleteDialog(false);
		}
	};

	const handleCloseError = () => {
		setError(null);
	};

	if (isLoading) return <Loading />;

	const description = "Let's create your first event to track.";

	return (
		<ThemeProvider theme={theme}>
			<FormProvider {...methods}>
				<Box height='95%' display='flex' flexDirection='column'>
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'
					>
						<Typography className={classes.title}>Event settings</Typography>
						<Box display='flex' gridGap={16}>
							{!isUser && (
								<>
									<Tooltip
										title={
											selectedProfiles.length === 0
												? 'Select event profiles to delete using the checkboxes to the left of each event profile.'
												: ''
										}
									>
										<span>
											<DeleteButton
												variant='outlined'
												disabled={selectedProfiles.length === 0}
												onClick={handleDeleteClick}
											>
												Delete ({selectedProfiles.length})
											</DeleteButton>
										</span>
									</Tooltip>
									<Button
										className={(classes.button, classes.saveButton)}
										onClick={handleClickNewEvent}
										variant='contained'
										color='primary'
									>
										New Event
									</Button>
								</>
							)}
						</Box>
					</Box>
					<Box style={{ overflowY: 'auto', height: 'inherit' }}>
						{eventProfiles?.list?.length === 0 ? (
							<EmptyHistory
								description={description}
								showButton={!isUser}
								onClickNewEvent={handleClickNewEvent}
							/>
						) : (
							<Box>
								<EventSettingsList
									eventProfiles={listEventProfiles}
									onRowClick={handleClickRow}
									onSubscribeClick={handleSubscribeClick}
									onSelectProfile={handleSelectProfile}
									selectedProfiles={selectedProfiles}
								/>
							</Box>
						)}
					</Box>
				</Box>
				{openStepper && (
					<EventStepper onCancel={handleCancel} isOpen={openStepper} />
				)}
				{open && currentEventProfile && (
					<EventSubscribe
						open={open}
						eventProfile={currentEventProfile}
						onGoBack={handleClose}
						onSave={handleSaveSubs}
					/>
				)}
				<ConfirmationModal
					open={showDeleteDialog}
					onCancel={() => setShowDeleteDialog(false)}
					onAccept={handleConfirmDelete}
					onClose={() => setShowDeleteDialog(false)}
					title='Delete event profiles'
					text={`You are about to delete ${selectedProfiles.length} event profiles. This action cannot be undone. Do you want to continue?`}
					cancelButtonLabel='CANCEL'
					acceptButtonLabel='CONFIRM'
					acceptButtonColor='primary'
					showCloseButton
				/>
				<Snackbar
					open={!!error}
					autoHideDuration={6000}
					onClose={handleCloseError}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				>
					<Alert onClose={handleCloseError} severity='error'>
						{error}
					</Alert>
				</Snackbar>
			</FormProvider>
		</ThemeProvider>
	);
}

export default EventSettings;
