import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Icon } from '@mdi/react';

import icons from '../../../style/icons';
import global from '../../../res/global';

const countryMap = global.countries.reduce((acc, country) => {
  acc[country.code] = country;
  return acc;
}, {});

export default function DecoratedCountrySelectAutocomplete(props) {
  let iconPath = null;
  // allow override of variant icon by setting icon path directly.
  if (props.iconPath) {
    iconPath = props.iconPath;
  } else {
    iconPath = icons[props.conservField];
  }

  return (
    <Box
      display='flex'
      flexWrap='nowrap'
      alignItems='flex-end'
      flexDirection='row'
      style={{ width: '100%' }}
    >
      <Box pb={props.editing ? 1 : 1.5} style={{ minWidth: '24px', maxWidth: '24px', marginBottom: '8px' }}>
        {iconPath ? <Icon path={iconPath} size={1.2} /> : null}
      </Box>
      <Box pl={2} flexGrow={1}>
        <Autocomplete
          id='country-select-autocomplete'
          {...props}
          options={global.countries}
          value={countryMap[props.value]}
          onChange={(_event, newValue) => {
            if (newValue) {
              props.onChange({ target: { id: 'country', value: newValue.code } });
            }
          }}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component='li'
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...optionProps}
              >
                <img
                  loading='lazy'
                  width='20'
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  alt='Country flag'
                />
                {option.label}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Choose a country'
              slotProps={{
                htmlInput: {
                  ...params.inputProps,
                  // disable autocomplete and autofill
                  autoComplete: 'new-password',
                },
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
}
