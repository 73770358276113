import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import {
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	Typography
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import EventParameters from '../../EventParameters';
import EventScope from '../../EventScope';
import SubscribersCell from './SubscribersCell';
import TrackingSwitch from './TrackingSwitch';
import Loading from '../../../common/elements/Loading';
import theme from '../../../../style/themev2';
import useStyles from './styles';
import useRoles from 'legacy/hooks/useRoles';

function EventSettingsList({ eventProfiles, onRowClick, onSubscribeClick, onSelectProfile, selectedProfiles }) {
	const classes = useStyles();
	const { isUser } = useRoles();

	const handleClickRow = eventProfile => {
		if (!isUser) {
			onRowClick(eventProfile);
		}
	};

	const handleCheckboxClick = (event, uuid) => {
		event.stopPropagation();
		onSelectProfile(uuid, !selectedProfiles.includes(uuid));
	};

	const handleSubscribeClick = eventProfile => event => {
		event.stopPropagation();
		onSubscribeClick(eventProfile);
	};

	const headerConfig = [
		...(!isUser ? [{
			id: 0,
			name: '',
			width: '5%',
			render: (eventProfile) => (
				<Checkbox
					checked={selectedProfiles.includes(eventProfile.uuid)}
					onClick={(e) => handleCheckboxClick(e, eventProfile.uuid)}
					color="primary"
				/>
			)
		}] : []),
		{
			id: 1,
			name: 'Name',
			sortKey: 'name',
			width: '15%'
		},
		{
			id: 2,
			name: 'Related to',
			sortKey: 'scope',
			width: '20%'
		},
		{
			id: 3,
			name: 'Condition',
			sortKey: 'condition',
			width: '25%'
		},
		{
			id: 4,
			name: 'Subscribers',
			sortKey: 'eventsubs',
			width: '15%'
		},
		{
			id: 5,
			name: 'Tracking',
			sortKey: 'tracking',
			width: '15%'
		},
	];

	if (!eventProfiles) return <Loading />;

	return (
		<ThemeProvider theme={theme}>
			<Table
				classes={{ root: classes.tableRoot }}
				aria-label='alert table'
				stickyHeader
			>
				<TableHead className={classes.tableHead}>
					<TableRow>
						{headerConfig.map(({ id, name, sortKey, width, render }) => (
							<TableCell
								className={classes.tableTitle}
								key={id}
								style={{ width }}
							>
								{render ? render : name}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{eventProfiles.length === 0 ? (
						<TableRow key={0}>
							<TableCell colSpan={7}>
								<Typography variant='h4'>No events found</Typography>
							</TableCell>
						</TableRow>
					) : (
						eventProfiles.map(eventProfile => (
							<TableRow
								key={eventProfile.uuid || eventProfile.id}
								className={classes.tableRow}
							>
								{/* Only render checkbox cell for admins and superadmins */}
								{!isUser && (
									<TableCell>
										<Checkbox
											checked={selectedProfiles.includes(eventProfile.uuid)}
											onClick={(e) => handleCheckboxClick(e, eventProfile.uuid)}
											color="primary"
										/>
									</TableCell>
								)}
								<TableCell
									className={classes.tableCell}
									onClick={e => handleClickRow(eventProfile)}
								>
									<Typography
										variant='body2'
										noWrap
										className={
											eventProfile.unread ? classes.tableCellTextBold : ''
										}
									>
										{eventProfile.name}
									</Typography>
								</TableCell>
								<TableCell
									className={classes.tableCell}
									onClick={e => handleClickRow(eventProfile)}
								>
									<Typography
										variant='body2'
										noWrap
										className={
											eventProfile.unread ? classes.tableCellTextBold : ''
										}
									>
										<EventScope eventProfile={eventProfile} />
									</Typography>
								</TableCell>
								<TableCell
									className={classes.tableCell}
									onClick={e => handleClickRow(eventProfile)}
								>
									<Typography
										variant='body2'
										noWrap
										className={
											eventProfile.unread ? classes.tableCellTextBold : ''
										}
									>
										<EventParameters eventProfile={eventProfile} />
									</Typography>
								</TableCell>
								<TableCell
									className={classes.tableCell}
									onClick={handleSubscribeClick(eventProfile)}
								>
									<SubscribersCell eventsubs={eventProfile.eventsubs} />
								</TableCell>
								<TableCell className={classes.tableCell}>
									<TrackingSwitch
										eventProfile={eventProfile}
										disabled={isUser}
									/>
								</TableCell>
							</TableRow>
						))
					)}
				</TableBody>
			</Table>
		</ThemeProvider>
	);
}

export default EventSettingsList;
