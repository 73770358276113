import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import Api from '../../../api/Api';

const ImportProgressMessages = {
  UPLOADING: 'Uploading file...',
  UPLOAD_COMPLETED: 'You may close this window as your file is being processed in the background. You will receive an email when the upload is complete.\n\n' +
    'Note: you will not see your data show up in the Sensors tab immediately. After you receive the confirmation email, refresh your browser to see your data.',
};

function ImportingStep({ importConfig, onUpdateImportConfig, onError }) {
  const [importProgress, setImportProgress] = useState(ImportProgressMessages.UPLOADING);

  const handleUpload = useCallback(async () => {
    try {
      await Api.startImportAsync(importConfig.groupUuid);

      const uploadPromises = importConfig.uploadFiles.map(async file => {
        const { data: { url } } = await Api.getImportUploadUrl(file.uuid, file.file.type);
        return Api.putImportFileS3(url, file.file, {
          'Content-Type': file.file.type
        });
      });

      await Promise.all(uploadPromises);
      setImportProgress(ImportProgressMessages.UPLOAD_COMPLETED);

      const sensors = importConfig.uploadFiles.map(file => {
        return {
          uuid: file.uuid,
          name: file.file.name,
          type: file.file.type,
        };
      });
      const newConfig = { ...importConfig };
      newConfig.phase = 'complete';
      newConfig.importResult = { sensors };
      onUpdateImportConfig(newConfig);
    } catch (err) {
      onError({
        fatal: false,
        message: 'There was an error processing this import file, please contact Conserv support.'
      });
    }
  }, [importConfig.groupUuid, importConfig.uploadFiles, onError]);

  useEffect(() => {
    handleUpload();
  }, [handleUpload]);

  return (
    <Box
      mt={4}
      width='100%'
      minHeight='120px'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <Box pt={2}>
        <Typography variant='body1' style={{ whiteSpace: 'pre-line' }}>
          {importProgress}
        </Typography>
      </Box>
    </Box>
  );
}

export default ImportingStep;
