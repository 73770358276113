import React, { useState, useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import { useCookies } from 'react-cookie';
import {
	Dialog,
	DialogContent,
	DialogActions,
	Stepper,
	Step,
	StepLabel,
	Button,
	IconButton,
	Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import FileTypeStep from './FileTypeStep';
import ImportingStep from './ImportingStep';
import MapDataStep from './MapDataStep';
import UploadFileStep from './UploadFileStep';
import AssignSensorsStep from './AssignSensorsStep';
import GradientDialogHeader from '../../common/dialog/GradientDialogHeader';
import Api from '../../../api/Api';
import SegmentUtil from '../../../util/SegmentUtil';
import isFreeCustomer from '../../../../services/customer/isFreeCustomer';
import useRoles from '../../../hooks/useRoles';
import DataFormattingStep from './DataFormattingStep';

const useStyles = makeStyles({
	importDialogPaper: {
		width: '800px'
	},
	closeButton: {
		position: 'absolute',
		right: '25px',
		top: '15px'
	}
});

function ImportDialog() {
	const classes = useStyles();

	const [activeStep, setActiveStep] = useState(0);
	const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);
	const [importing] = useState(false);
	const [importConfig, setImportConfig] = useState({});
	const [error, setError] = useState();
	const [isAFreeCustomer, setIsAFreeCustomer] = useState(false);
	const { dispatch, customer } = useStoreon('customer');
	const [cookies, setCookie] = useCookies(['showStarterKitOffer']);
	const { isAdmin, isSuperAdmin } = useRoles();

	useEffect(() => {
		SegmentUtil.track(SegmentUtil.actions.importdata);
		dispatch('customer/refresh');
		const asyncFunction = async () => {
			const isFree = await isFreeCustomer();
			setIsAFreeCustomer(isFree);
		};
		void asyncFunction();
	}, []);

	useEffect(() => {
		if (error) {
			Sentry.captureException(new Error(error.message));
		}
	}, [error]);

	useEffect(() => {
		if (importConfig.uploadFiles) {
			importConfig.uploadFiles.forEach(uploadFile => {
				if (uploadFile.uuid) {
					// first, write the existing parser config to the DB, along with some sample rows (5 max);
					Api.updateImport(uploadFile.uuid, {
						importconfig: {
							...uploadFile.parserConfig,
							format: importConfig.format,
							sensorResult: importConfig.sensorResult
						},
						metadata: {
							fileType: uploadFile.fileType,
						},
						samplerows: uploadFile.firstRows,
						phase: uploadFile.phase || 'none',
						groupuuid: uploadFile.groupUuid,
					})
						.then(() => {})
						.catch(err => {
							dispatch('notification/add', {
								message: 'There was an error updating the import configuration',
								severity: 'error',
								variant: 'banner'
							});
						});
				}
			});
		}
	}, [importConfig]);

	const steps = [
		{
			name: 'Sensor Type',
			stepComponent: (
				<FileTypeStep
					importConfig={importConfig}
					onUpdateImportConfig={setImportConfig}
				/>
			),
			readyFunction: () => !importConfig.fileType
		},
		{
			name: 'Upload',
			stepComponent: (
				<UploadFileStep
					importConfig={importConfig}
					onUpdateImportConfig={setImportConfig}
					onError={setError}
				/>
			),
			readyFunction: () =>
				!(
					Boolean(importConfig.fileValidation?.valid) &&
					Boolean(importConfig.uploadFiles?.length > 0)
				)
		},
		{
			name: 'Mapping',
			stepComponent: (
				<MapDataStep
					importConfig={importConfig}
					onUpdateImportConfig={setImportConfig}
					onError={setError}
				/>
			),
			readyFunction: () => !importConfig.mappingValidation?.valid
		},
		{
			name: 'Data Formatting',
			stepComponent: (
				<DataFormattingStep
					importConfig={importConfig}
					onUpdateImportConfig={setImportConfig}
					onError={setError}
				/>
			),
			readyFunction: () => !importConfig.formatResult
		},
		{
			name: 'Sensors',
			stepComponent: (
				<AssignSensorsStep
					importConfig={importConfig}
					onUpdateImportConfig={setImportConfig}
					onError={setError}
				/>
			),
			readyFunction: () => !importConfig.sensorResult?.valid
		},
		{
			name: 'Import',
			stepComponent: (
				<ImportingStep
					importConfig={importConfig}
					onUpdateImportConfig={setImportConfig}
					onError={setError}
				/>
			),
			readyFunction: () => !importConfig.importResult
		}
	];

	const onCancel = () => {
		dispatch('navstate/update', { dialogOpen: false, dialogContent: null });
	};

	// @deprecated
	const runSensorActions = () => {
		const actionPromises = [];
		const sensorActionKeys = Object.keys(finalActions.sensorActions);
		sensorActionKeys.forEach(sensorUuid => {
			const action = finalActions.sensorActions[sensorUuid];
			if (action.actionName === 'space') {
				actionPromises.push(handleMove(sensorUuid, action.actionTargetUuid));
			}
			if (action.actionName === 'sensor') {
				actionPromises.push(handleMerge(sensorUuid, action.actionTargetUuid));
			}
		});
		const sensorRenameKeys = Object.keys(finalActions.sensorNames);
		sensorRenameKeys.forEach(sensorUuid => {
			actionPromises.push(
				renameSensor(sensorUuid, finalActions.sensorNames[sensorUuid])
			);
		});
		Promise.all(actionPromises)
			.then(() => {
				// Check for the cookie to offer the Starter5 Kit
				if (
					!cookies.showStarterKitOffer &&
					isAFreeCustomer &&
					(isAdmin || isSuperAdmin)
				) {
					// Set a cookie to not show the Starter Kit offer for 24 hs
					setCookie('showStarterKitOffer', 'yes', {
						path: '/',
						maxAge: 60 * 60 * 24
					});
				}

				dispatch('navstate/update', { dialogOpen: false, dialogContent: null });
				window.location.href = '/sensors';
			})
			.catch(() => {});
	};


	const finishImport = () => {
		SegmentUtil.track(SegmentUtil.actions.importcomplete);

		dispatch('navstate/update', { dialogOpen: false, dialogContent: null });
		window.location.href = '/sensors';
	}

	const getStep = () => {
		if (activeStep < steps.length) {
			return steps[activeStep].stepComponent;
		}
		return <Box>Something Went Wrong....</Box>;
	};

	const handleMerge = (newSensorUuid, existingUuid) => {
		const mergeDetails = {
			mergenote: 'Merged during import process',
			sensorId: newSensorUuid,
			supersededby: existingUuid
		};
		return Api.mergeSensor(mergeDetails);
	};

	const handleMove = (sensorUuid, spaceUuid) => {
		const moveDetails = {
			toZone: spaceUuid,
			moveNote: 'Moved into space during import process',
			moveDateTime: new Date().getTime(),
			sensorId: sensorUuid
		};
		return Api.moveSensor(moveDetails);
	};

	const renameSensor = (sensorUuid, sensorName) =>
		Api.updateSensor({ data: { uuid: sensorUuid, name: sensorName } });

	return (
		<>
			<Dialog open={cancelConfirmOpen}>
				<GradientDialogHeader
					title='Confirm Cancellation'
					variant='danger'
					subtitle='Are you sure you want to cancel this import?'
				/>
				<DialogActions>
					<Button onClick={onCancel} variant='contained' color='secondary'>
						Cancel Import
					</Button>
					<Button
						onClick={() => setCancelConfirmOpen(false)}
						variant='contained'
						color='grey'
					>
						Continue Import
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={Boolean(error)}>
				<GradientDialogHeader
					title='An error has occurred'
					variant='danger'
					subtitle={
						error?.fatal
							? 'An error has occurred, this import cannot proceed'
							: error?.message || ''
					}
				/>
				<DialogActions>
					<Button
						onClick={
							error?.fatal
								? onCancel
								: () => {
										setError(null);
										onCancel();
								  }
						}
						variant='contained'
						color='primary'
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open
				classes={{ paper: classes.importDialogPaper }}
				fullWidth
				maxWidth='md'
			>
				<Box>
					<IconButton
						edge='end'
						aria-label='close'
						className={classes.closeButton}
						onClick={() => {
							if (activeStep > 0) {
								setCancelConfirmOpen(true);
							} else {
								onCancel();
							}
						}}>
						<CloseIcon />
					</IconButton>
				</Box>
				<DialogContent>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(step => (
							<Step key={step.name}>
								<StepLabel>{step.name}</StepLabel>
							</Step>
						))}
					</Stepper>
					{getStep()}
				</DialogContent>
				<DialogActions>
					<Box
						display='flex'
						flexDirection='row'
						justifyContent='space-between'
						width='100%'
					>
						<Box>
							{activeStep > 0 && activeStep < 3 ? (
								<Box pr={1} display='inline'>
									<Button
										disabled={importing}
										onClick={() => setActiveStep(activeStep - 1)}
										variant='text'
										color='primary'
										data-testid={`go-back-${activeStep}`}
									>
										Go Back
									</Button>
								</Box>
							) : null}
						</Box>
						{activeStep < 5 ? (
							<Button
								disabled={steps[activeStep].readyFunction()}
								onClick={() => setActiveStep(activeStep + 1)}
								variant='contained'
								color='primary'
								data-testid={`next-step`}
							>
								Next Step
							</Button>
						) : (
							<Button
								onClick={finishImport}
								disabled={!importConfig.importResult}
								variant='contained'
								color='primary'
								data-testid='finish-button'
							>
								Finish
							</Button>
						)}
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default ImportDialog;
