/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { useMemo } from 'react';
import { useStoreon } from 'storeon/react';
import { calcFahrenheitFromCelsius, calcCelsiusFromFahrenheit } from '@conserv/mnmx-io-common/dist/temperature/temperatureUtil';
import { convertFromLuxToFootcandle, convertFromFootcandleToLux } from '@conserv/mnmx-io-common/dist/illumination/illuminationUtil';
import calcMicroWperCm2toMicroWperLumen from 'utils/measurement/calculateMicroWperCm2toMicroWperLumen';
import calcMicroWperLumentoMicroWperCm2 from 'utils/measurement/calcMicroWperLumentoMicroWperCm2';
import { StoreState, UserPreferences } from 'types/StoreOn';
import calcFahrenheitVariationFromCelsiusVariation from 'utils/measurement/calcFahrenheitVariationFromCelsiusVariation';
import calcCelsiusVariationFromFahrenheitVariation from 'utils/measurement/calcCelsiusVariationFromFahrenheitVariation';
import { UVUnits } from 'config/constants';
import Api from 'legacy/api/Api';
import { ScaleSettings } from 'types/ScaleSettings';

function useUserPreferencesAndFormat() {
	const { preferences: storePreferences, dispatch } =
		useStoreon<StoreState>('preferences');

	const memoizedValues = useMemo(() => {
		const formatTemperature = (rawTemperature: number): string =>
			(storePreferences.tempDisplay === 'fahrenheit'
				? calcFahrenheitFromCelsius(rawTemperature)
				: rawTemperature
			).toFixed(2);

		const formatTemperatureFluctuation = (rawTemperature: number): string =>
			(storePreferences.tempDisplay === 'fahrenheit'
				? calcFahrenheitVariationFromCelsiusVariation(rawTemperature)
				: rawTemperature
			).toFixed(2);

		const formatTemperatureFluctuationToCelcius = (
			rawTemperature: number
		): number =>
			storePreferences.tempDisplay === 'fahrenheit'
				? calcCelsiusVariationFromFahrenheitVariation(rawTemperature)
				: rawTemperature;

		const formatIlluminance = (rawIlluminance: number): string =>
			(storePreferences.illuminanceDisplay === 'footcandle'
				? convertFromLuxToFootcandle(rawIlluminance)
				: rawIlluminance
			).toFixed(2);

		const formatUv = (rawUv: number, rawIlluminance: number): string =>
			(storePreferences.uvDisplay === UVUnits.MicroWattsPerLumen
				? calcMicroWperCm2toMicroWperLumen(rawUv, rawIlluminance)
				: rawUv
			).toFixed(2);

		const updatePreferences = async (
			updatedPreferences: UserPreferences
		): Promise<void> => {
				await Api.updatePreferences({ ...updatedPreferences });
				dispatch('preferences/refresh2');
		};

		const convertScaleSettingToStandardUnit = (
			type: keyof ScaleSettings,
			value: number
		): number => {
			switch (type) {
				case 'temperature': {
					return storePreferences.tempDisplay === 'fahrenheit'
						? calcCelsiusFromFahrenheit(value)
						: value;
				}
				case 'illuminance': {
					return storePreferences.illuminanceDisplay === 'footcandle'
						? convertFromFootcandleToLux(value)
						: value;
				}
				case 'uv': {
					return storePreferences.uvDisplay === UVUnits.MicroWattsPerLumen
						? calcMicroWperLumentoMicroWperCm2(value, 1)
						: value;
				}
				default: {
					return value;
				}
			}
		};

		const convertScaleSettingFromStandardUnit = (
			type: keyof ScaleSettings,
			value: number
		): number => {
			switch (type) {
				case 'temperature': {
					return storePreferences.tempDisplay === 'fahrenheit'
						? calcFahrenheitFromCelsius(value)
						: value;
				}
				case 'illuminance': {
					return storePreferences.illuminanceDisplay === 'footcandle'
						? convertFromLuxToFootcandle(value)
						: value;
				}
				case 'uv': {
					return storePreferences.uvDisplay === UVUnits.MicroWattsPerLumen
						? calcMicroWperCm2toMicroWperLumen(value, 1)
						: value;
				}
				default: {
					return value;
				}
			}
		};

		return {
			preferences: {
				temperature: storePreferences.tempDisplay,
				illuminance: storePreferences.illuminanceDisplay,
				uv: storePreferences.uvDisplay,
				showKPIs: storePreferences.showKPIs,
				scaleSettings: storePreferences.scaleSettings,
				temperatureDisplayUnit:
					storePreferences.tempDisplay === 'fahrenheit' ? '°F' : '°C',
				illuminanceDisplayUnit:
					storePreferences.illuminanceDisplay === 'footcandle' ? 'fc' : 'lux',
				uvDisplayUnit:
					storePreferences.uvDisplay === UVUnits.MicroWattsPerSquareCentimetre
						? UVUnits.MicroWattsPerSquareCentimetre
						: UVUnits.MicroWattsPerLumen,
				rhDisplayUnit: '%'
			},
			storePreferences,
			formatTemperature,
			formatTemperatureFluctuation,
			formatIlluminance,
			formatTemperatureFluctuationToCelcius,
			formatUv,
			updatePreferences,
			convertScaleSettingToStandardUnit,
			convertScaleSettingFromStandardUnit
		};
	}, [dispatch, storePreferences]);

	return memoizedValues;
}

export default useUserPreferencesAndFormat;
