import { FAHRENHEIT } from '@conserv/mnmx-io-common/dist/temperature/temperatureUtil';
import React, { Component } from 'react';
import { connectStoreon } from 'storeon/react';
import { UVUnits } from 'config/constants';
import Api from '../api/Api';
const mnmxIoCommon = require('@conserv/mnmx-io-common');
const temperatureUtil = mnmxIoCommon.temperatureUtil;

export const PreferencesContext = React.createContext();
export const PreferencesConsumer = PreferencesContext.Consumer;

class PreferencesProvider extends Component {
	defaults = {
		tempDisplay: temperatureUtil.FAHRENHEIT,
		illuminanceDisplay: 'lux',
		distanceDisplay: 'feet',
		uvDisplay: UVUnits.MicroWattsPerLumen,
		useMFA: false,
		temp1: { graphMin: 0, graphMax: 38, graphAuto: true },
		rh: { graphMin: 40, graphMax: 80, graphAuto: true },
		vis: { graphMin: 0, graphMax: 500, graphAuto: true },
		uv1: { graphMin: 0, graphMax: 50, graphAuto: true },
		ir: { graphMin: 0, graphMax: 5000, graphAuto: true },
		dewpoint: { graphMin: 0, graphMax: 38, graphAuto: true }
	};

	tempconvertible = ['temp', 'temp1', 'temp2', 'dewpoint'];
	illuminanceconvertible = ['vis', 'illuminance'];
	distanceconvertible = ['distance', 'elevation'];
	areaconvertible = ['spacesize'];

	state = {
		preferences: this.defaults,
		updatePreferences: updatedPreferences =>
			this.updatePreferences(updatedPreferences),
		convertUnitToDisplay: (name, value, delta, round) =>
			this.convertUnitToDisplay(name, value, delta, round),
		convertUnitToApi: (name, value, delta) =>
			this.convertUnitToApi(name, value, delta),
		convertUnitToApi2: (name, value, delta) =>
			this.convertUnitToApi2(name, value, delta),
		getPreference: name => this.getPreference(name),
		getPreferences: () => this.getPreferences(),
		getDisplayUnit: name => this.getDisplayUnit(name)
	};

	componentDidMount() {
		const prefs = this.props.preferences;
		if (!prefs) {
			this.props.dispatch('preferences/refresh');
		}
	}

	updatePreferences = (updatedPreferences, noPersist) => {
		const newPrefs = {
			...this.defaults,
			...this.props.preferences,
			...updatedPreferences
		};
		this.setState(
			{ preferences: newPrefs },
			!noPersist ? () => this.persistPreferences(newPrefs) : null
		);
	};

	persistPreferences = newPrefs => {
		// if the preferences load hasn't completed, we risk overwriting preferences with defaults
		if (this.props.preferences) {
			Api.updatePreferences(newPrefs)
				.then(() => {
					this.props.dispatch('preferences/refresh', true);
				})
				.catch(err => {
					this.props.dispatch('notification/add', {
						message: 'There was an error saving your preferences',
						severity: 'warning',
						variant: 'banner'
					});
				});
		}
	};

	getPreference = name => {
		let val;
		if (this.props.preferences[name]) {
			val = this.props.preferences[name];
		} else {
			val = this.defaults[name];
		}
		return val;
	};

	getPreferences = () => this.state;

	is_temp_convertible(name) {
		let convertible = false;
		this.tempconvertible.forEach(option => {
			if (name.includes(option)) {
				convertible = true;
			}
		});
		return convertible;
	}

	is_illum_convertible(name) {
		let convertible = false;
		this.illuminanceconvertible.forEach(option => {
			if (name.includes(option)) {
				convertible = true;
			}
		});
		return convertible;
	}

	is_distance_convertible(name) {
		let convertible = false;
		this.distanceconvertible.forEach(option => {
			if (name.includes(option)) {
				convertible = true;
			}
		});
		return convertible;
	}

	is_area_convertible(name) {
		let convertible = false;
		this.areaconvertible.forEach(option => {
			if (name.includes(option)) {
				convertible = true;
			}
		});
		return convertible;
	}

	convert_temp = (fromunit, tounit, value, delta) => {
		switch (fromunit) {
			case temperatureUtil.CELSIUS:
				switch (tounit) {
					case temperatureUtil.FAHRENHEIT:
						return ((parseFloat(value) * 9) / 5 + (!delta ? 32 : 0)).toFixed(2);
					default:
						return parseFloat(value);
				}
			case temperatureUtil.FAHRENHEIT:
				switch (tounit) {
					case temperatureUtil.CELSIUS:
						return (((parseFloat(value) - (!delta ? 32 : 0)) * 5) / 9).toFixed(
							2
						);
					default:
						return parseFloat(value);
				}
			default:
				return parseFloat(value);
		}
	};

	convert_temp_to_api = (fromunit, tounit, value, delta) => {
		switch (fromunit) {
			case temperatureUtil.CELSIUS:
				switch (tounit) {
					case temperatureUtil.FAHRENHEIT:
						return (parseFloat(value) * 9) / 5 + (!delta ? 32 : 0).toFixed(4);
					default:
						return parseFloat(value?.toFixed(3));
				}
			case temperatureUtil.FAHRENHEIT:
				switch (tounit) {
					case temperatureUtil.CELSIUS:
						return (((parseFloat(value) - (!delta ? 32 : 0)) * 5) / 9).toFixed(
							4
						);
					default:
						return parseFloat(value).toFixed(3);
				}
			default:
				return parseFloat(value?.toFixed(3));
		}
	};

	convert_illum = (fromunit, tounit, value) => {
		switch (fromunit) {
			case 'lux':
				switch (tounit) {
					case 'lux':
						return value;
					case 'footcandle':
						return (value * 0.092903).toFixed(2);
					default:
						return value;
				}
			case 'footcandle':
				switch (tounit) {
					case 'footcandle':
						return value;
					case 'lux':
						return (value * 10.76391).toFixed(2);
					default:
						return value;
				}
			default:
				return value;
		}
	};

	convert_illum_to_api = (fromunit, tounit, value) => {
		switch (fromunit) {
			case 'lux':
				switch (tounit) {
					case 'lux':
						return parseFloat(value?.toFixed(3));
					case 'footcandle':
						return parseFloat((value * 0.092903).toFixed(3));
					default:
						return parseFloat(value?.toFixed(3));
				}
			case 'footcandle':
				switch (tounit) {
					case 'footcandle':
						return parseFloat(value?.toFixed(3));
					case 'lux':
						return (value * 10.76391).toFixed(3);
					default:
						return parseFloat(value?.toFixed(3));
				}
			default:
				return parseFloat(value?.toFixed(3));
		}
	};

	convert_distance = (fromunit, tounit, value) => {
		switch (fromunit) {
			case 'feet':
				switch (tounit) {
					case 'feet':
						return value;
					case 'meter':
						return (value * 3.281).toFixed(2);
					default:
						return value;
				}
			case 'meter':
				switch (tounit) {
					case 'feet':
						return (value / 3.281).toFixed(2);
					case 'meter':
						return value;
					default:
						return value;
				}
			default:
				return value;
		}
	};

	convert_distance_to_api = (fromunit, tounit, value) => {
		switch (fromunit) {
			case 'feet':
				switch (tounit) {
					case 'feet':
						return value;
					case 'meter':
						return (value * 3.281).toFixed(3);
					default:
						return value;
				}
			case 'meter':
				switch (tounit) {
					case 'feet':
						return (value / 3.281).toFixed(3);
					case 'meter':
						return value;
					default:
						return value;
				}
			default:
				return value;
		}
	};

	convert_area = (fromunit, tounit, value) => {
		switch (fromunit) {
			case 'feet':
				switch (tounit) {
					case 'feet':
						return value;
					case 'meter':
						return value / 10.7639;
					default:
						return value;
				}
			case 'meter':
				switch (tounit) {
					case 'feet':
						return value * 10.7639;
					case 'meter':
						return value;
					default:
						return value;
				}
			default:
				return value;
		}
	};

	getTempUnitDisplayPreferenceWithFallbackToFahrenheit = () => {
		return this.props?.preferences?.tempDisplay?.toLowerCase() || FAHRENHEIT;
	};

	convertUnitToDisplay = (attrName, value, delta, round) => {
		const oldSkool = this.convertUnitToDisplayOldSkool(
			attrName,
			value,
			delta,
			round
		);
		return oldSkool;
	};

	convertUnitToDisplayOldSkool = (name, value, delta, round) => {
		if (this.is_temp_convertible(name)) {
			let converted = this.convert_temp(
				'celsius',
				this.props.preferences.tempDisplay.toLowerCase(),
				value,
				delta
			);
			if (round) converted = Math.round(converted);
			return converted;
		}
		if (this.is_illum_convertible(name)) {
			let converted = this.convert_illum(
				'lux',
				this.props.preferences.illuminanceDisplay.toLowerCase(),
				value
			);
			if (round) converted = Math.round(converted);
			return converted;
		}
		if (this.is_distance_convertible(name)) {
			return this.convert_distance(
				this.props.preferences.distanceDisplay.toLowerCase(),
				'meter',
				value
			);
		}
		if (this.is_area_convertible(name)) {
			return this.convert_area(
				'meter',
				this.props.preferences.distanceDisplay.toLowerCase(),
				value,
				false
			);
		}
		return value;
	};

	convertUnitToApi = (name, value, delta) => {
		if (this.is_temp_convertible(name)) {
			return this.convert_temp(
				temperatureUtil.confirmValidTemperatureUnitOrFallbackToUserDisplayDefault(
					this.props?.preferences?.tempDisplay?.toLowerCase()
				),
				temperatureUtil.CELSIUS,
				value,
				delta
			);
		}
		if (this.is_illum_convertible(name)) {
			return this.convert_illum(
				this.props.preferences.illuminanceDisplay.toLowerCase(),
				'lux',
				value
			);
		}
		if (this.is_distance_convertible(name)) {
			return this.convert_distance(
				this.props.preferences.distanceDisplay.toLowerCase(),
				'meter',
				value
			);
		}
		if (this.is_area_convertible(name)) {
			return this.convert_area(
				this.props.preferences.distanceDisplay.toLowerCase(),
				'meter',
				value
			);
		}
		return value;
	};

	convertUnitToApi2 = (name, value, delta) => {
		if (this.is_temp_convertible(name)) {
			return this.convert_temp_to_api(
				// prob F
				temperatureUtil.confirmValidTemperatureUnitOrFallbackToUserDisplayDefault(
					this.props?.preferences?.tempDisplay?.toLowerCase()
				),
				temperatureUtil.CELSIUS,
				value,
				delta
			);
		}
		if (this.is_illum_convertible(name)) {
			return this.convert_illum_to_api(
				this.props.preferences.illuminanceDisplay.toLowerCase(),
				'lux',
				value
			);
		}
		if (this.is_distance_convertible(name)) {
			return this.convert_distance_to_api(
				this.props.preferences.distanceDisplay.toLowerCase(),
				'meter',
				value
			);
		}
		if (this.is_area_convertible(name)) {
			return this.convert_area(
				this.props.preferences.distanceDisplay.toLowerCase(),
				'meter',
				value
			);
		}
		return value;
	};

	getDisplayUnit = name => {
		if (this.is_temp_convertible(name)) {
			return temperatureUtil.confirmValidTemperatureUnitOrFallbackToUserDisplayDefault(
				this.props?.preferences?.tempDisplay?.toLowerCase()
			);
		}
		if (this.is_illum_convertible(name)) {
			return this.props.preferences.illuminanceDisplay.toLowerCase();
		}
		if (this.is_distance_convertible(name)) {
			return this.props.preferences.distanceDisplay.toLowerCase();
		}
		return name;
	};

	render() {
		const contextState = this.state;
		contextState.preferences = this.props.preferences;
		return (
			<PreferencesContext.Provider value={contextState}>
				{this.props.children}
			</PreferencesContext.Provider>
		);
	}
}

export default connectStoreon('preferences', PreferencesProvider);
