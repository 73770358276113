import { Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Entry from './components/account/Entry';
import Logout from './components/Logout';
import VerifyEmailLanding from './components/account/verify/VerifyEmailLanding';
// import Maintenance from './containers/Maintenance';
const SentryRoute = Sentry.withSentryRouting(Route);

export default function (props) {
	return (
		<Switch>
			{/* <Route path='/login' exact component = {Entry}/> */}
			<SentryRoute
				path='/login'
				exact
				render={rprops => <Entry onLogin={props.onLogin} {...rprops} />}
			/>
			<SentryRoute path='/logout' exact component={Logout} />
			<SentryRoute
				path='/public/register'
				exact
				render={rprops => <Entry onLogin={props.onLogin} {...rprops} />}
			/>
			<SentryRoute path='/public/passwordreset' exact component={Entry} />
			<SentryRoute path='/public/acceptinvite' exact component={Entry} />
			<SentryRoute
				path='/public/referral/:referralcode'
				exact
				component={Entry}
			/>
			<SentryRoute
				path='/public/verifyemail'
				exact
				component={VerifyEmailLanding}
			/>
			{/* <SentryRoute path='/maintenance' exact component={Maintenance} /> */}
		</Switch>
	);
}
