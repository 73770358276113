import React, { useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@mdi/react';
import ImportDialog from '../components/import/v2/ImportDialog';
import { ImportList } from '../components/import/ImportList';
import icons from '../style/icons';
import { buttonStyles } from '../style/styles';

const useStyles = makeStyles({
	...buttonStyles
});

function Import(props) {
	const classes = useStyles();

	const actions = [
		{
			icon: (
				<Icon
					classes={{ root: classes.collapse }}
					size={1}
					path={icons.upload}
				/>
			),
			name: 'Import Data',
			callback: () =>
				dispatch('navstate/update', {
					dialogOpen: true,
					dialogContent: <ImportDialog />
				})
		}
	];

	const { dispatch, importFiles } = useStoreon('importFiles');

	useEffect(() => {
		props.setFab(actions);
		dispatch('navstate/update', { pageTitle: 'Import/Export' });
		dispatch('sensors/refresh');
		dispatch('spaces/refresh');
		dispatch('importFiles/refresh');
		return () => {
			props.setFab(null);
		};
	}, []);

	return (
		<Box padding={2}>
			<ImportList importfiles={importFiles || []} />
		</Box>
	);
}

export default Import;
