/**
 * Converts UV measurement from µW/lm to µW/cm²
 * @param microWperLumen UV measurement in µW/lm
 * @param illuminance Illuminance in lux
 * @returns UV measurement in µW/cm²
 */
export default function calcMicroWperLumentoMicroWperCm2(
  microWperLumen: number,
  illuminance: number
): number {
  return (microWperLumen * illuminance) / 100;
} 