import React, { useEffect, useState } from 'react';
import { useStoreon } from 'storeon/react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import uuid4 from 'uuid4';
import GradientDialogHeader from '../common/dialog/GradientDialogHeader';
import DecoratedTextField from '../common/form/DecoratedTextField';
import DecoratedCountrySelectAutocomplete from '../common/form/DecoratedCountrySelectAutocomplete';
import { buttonStyles } from '../../style/styles';
import Api from '../../api/Api';

const useStyles = makeStyles(() => ({
	...buttonStyles
}));

function CreateAccountDialog(props) {
	const [email, setEmail] = useState();
	const [customerName, setCustomerName] = useState();
	const [country, setCountry] = useState();
	const [formValidation, setFormValidation] = useState({});
	const { dispatch } = useStoreon();
	const classes = useStyles();

	const handleClose = () => {
		dispatch('navstate/update', { dialogOpen: false, dialogContent: null });
	};

	useEffect(() => {
		validateForm();
	}, [email, customerName, country]);

	const createAccount = () => {
		// create a new registration, use a uuid as the temporary password.  User will need to reset this before they can
		// log in.
		const newReg = {
			first_name: '',
			last_name: '',
			country: country,
			password: uuid4(),
			email,
			orgname: customerName,
		};
		Api.createRegistration(newReg)
			.then(regresult => {
				Api.convertRegistration(newReg, regresult.data.uuid)
					.then(() => {
						dispatch('notification/add', {
							message: 'Account invitation sent',
							severity: 'success',
							variant: 'snackbar'
						});
						handleClose();
					})
					.catch(() => {
						dispatch('notification/add', {
							message:
								'Could not convert registration for new customer account',
							severity: 'error',
							variant: 'banner'
						});
					});
			})
			.catch(() => {
				dispatch('notification/add', {
					message: 'Could not create new user registration',
					severity: 'error',
					variant: 'banner'
				});
			});
	};

	const validateForm = async () => {
		let valid = true;
		const formValidation = {};
		if (email && email.indexOf('@') != -1) {
			try {
				const { data } = await Api.validateEmail(email);
				if (!data.valid) {
					valid = false;
					formValidation.emailError = 'A valid email address is required';
				}
				if (data.emailexists) {
					formValidation.emailError =
						'There is already an account associated with this address';
				}
				if (!customerName || customerName?.length < 1) {
					valid = false;
					formValidation.customerName = 'The customer name is mandatory';
				}
				if (!country || country?.length < 1) {
					valid = false;
					formValidation.country = 'The country is mandatory';
				}
				setFormValidation({ formValid: valid, formValidation });
			} catch {
				dispatch('notification/add', {
					message: 'Could not validate the email',
					severity: 'error',
					variant: 'banner'
				});
			}
		} else {
			valid = false;
		}
	};

	return (
		<Dialog
			open
			onClose={handleClose}
			aria-labelledby='form-dialog-title'
			classes={{ paper: classes.paper }}
		>
			<GradientDialogHeader
				title='Create New Customer'
				subtitle='Set/add monitoring after adding from table view menu'
				onClose={handleClose}
			/>
			<DialogContent style={{ width: 'auto' }}>
				<DecoratedTextField
					required
					conservField='name'
					id='customerName'
					label='Organization Name'
					error={Boolean(formValidation?.formValidation?.customerName)}
					helperText={formValidation?.formValidation?.customerName}
					aria-label='customer name'
					onChange={ev => setCustomerName(ev.target.value)}
					value={customerName}
					editing
				/>

				<DecoratedTextField
					required
					conservField='email'
					id='email'
					label='Email Address'
					error={Boolean(formValidation?.formValidation?.emailError)}
					helperText={formValidation?.formValidation?.emailError}
					aria-label='email address'
					onChange={ev => setEmail(ev.target.value.trim())}
					value={email}
					editing
				/>
				<DecoratedCountrySelectAutocomplete
					id='country'
					required
					conservField='country'
					aria-label='country'
					style={{ marginTop: '15pt' }}
					onChange={ev => setCountry(ev.target.value)}
					value={country ?? ''}
					editing={true}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={!formValidation.formValid}
					variant='contained'
					color='primary'
					disableRipple
					onClick={createAccount}
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CreateAccountDialog;
