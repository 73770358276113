import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import SegmentUtil from '../util/SegmentUtil';

class Logout extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	componentDidMount() {
		const { cookies } = this.props;
		cookies.remove('jwt', { domain: '.conserv.io', path: '/' });
		SegmentUtil.track(SegmentUtil.actions.signedout);

		// if this user has an active Google Auth session, kill it
		googleLogout();
	}

	render() {
		return <Redirect to='/login' />;
	}
}

export default withCookies(Logout);
